var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "90vh",
      "width": _vm.isMobile ? '90vw' : '700px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop-title"
  }, [_vm._v(_vm._s(_vm.$lang("Task")))]), _c('div', {
    staticClass: "taskbox"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("标题")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.title,
      expression: "modifyData.title"
    }],
    domProps: {
      "value": _vm.modifyData.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "title", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("图标")]), _c('div', {
    staticClass: "input file-upload"
  }, [_vm.modifyData.icon ? _c('img', {
    attrs: {
      "src": _vm.modifyData.icon,
      "height": "40px",
      "width": "40px"
    }
  }) : _vm._e(), !_vm.modifyData.icon ? _c('svg-icon', {
    attrs: {
      "name": "upload-filled"
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.onFileChange($event, 'fileInput', 'icon');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("背景")]), _c('div', {
    staticClass: "input file-upload"
  }, [_vm.modifyData.background ? _c('img', {
    attrs: {
      "src": _vm.modifyData.background,
      "height": "40px"
    }
  }) : _vm._e(), !_vm.modifyData.background ? _c('svg-icon', {
    attrs: {
      "name": "upload-filled"
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput1",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.onFileChange($event, 'fileInput1', 'background');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("代币")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.chainToken,
      expression: "modifyData.chainToken"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "chainToken", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.$parent.chainList, function (item, index) {
    return _c('optgroup', {
      attrs: {
        "label": item.id
      }
    }, _vm._l(item.tokens, function (sitem, index) {
      return _c('option', {
        domProps: {
          "value": `${sitem.chain}|${sitem.symbol}`
        }
      }, [_vm._v(" " + _vm._s(sitem.symbol) + " ")]);
    }), 0);
  }), 0)])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("空投金额")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.amount,
      expression: "modifyData.amount"
    }],
    domProps: {
      "value": _vm.modifyData.amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "amount", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("空投总量")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.supply,
      expression: "modifyData.supply"
    }],
    domProps: {
      "value": _vm.modifyData.supply
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "supply", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-tip"
  }, [_vm._v(" 0表示无限供应 ")]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("邀请奖励金额")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.invitation_amount,
      expression: "modifyData.invitation_amount"
    }],
    domProps: {
      "value": _vm.modifyData.invitation_amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "invitation_amount", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("邀请奖励提示语")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.invitation_tip,
      expression: "modifyData.invitation_tip"
    }],
    domProps: {
      "value": _vm.modifyData.invitation_tip
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "invitation_tip", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("规则说明"), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.addRuleItem();
      }
    }
  }, [_vm._v("【+】")])]), _c('div', {
    staticClass: "input rules"
  }, _vm._l(_vm.modifyData.rules, function (item, index) {
    return _c('div', {
      staticClass: "ritem"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.modifyData.rules[index],
        expression: "modifyData.rules[index]"
      }],
      domProps: {
        "value": _vm.modifyData.rules[index]
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.modifyData.rules, index, $event.target.value);
        }
      }
    }), _vm._v(" "), _c('span', {
      on: {
        "click": function ($event) {
          return _vm.removeRuleItem(index);
        }
      }
    }, [_vm._v("【-】")])]);
  }), 0)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("状态")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.status,
      expression: "modifyData.status"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("启用")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("禁用")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("排序")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.sort,
      expression: "modifyData.sort"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.modifyData.sort
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "sort", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-tip"
  }, [_vm._v(" 越小排越前 ")])])]), _c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };